
/* .imageCard {
    text-align: center;
    width: 100px;
    height: 100px;

  } */

  .imageCard {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100px;
    height: 100px;
    border: 1px solid #ccc;
    border-radius: 5px;
    overflow: hidden;
    margin: 9px;
  }
  
  .imageCard img {
    max-width: 100%;
    height: auto;
  }
  
  .backdrop {
    position: fixed;
    background-color: black;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
  
  .crop-container {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 80px;
  }
  
  .controls {
    padding-bottom: 80px;
    position: fixed;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    height: 80px;
    display: flex;
    justify-content: center;
  }
  
  
  .controls-upper-area {
    text-align: center;
  }
  
  .slider {
    width: 50%;
  }
  
  /* .button-area {
    text-align: center;
    margin-top: 20px;
  }
  button {
    margin-left: 10px;
    margin-right: 10px;
    background-color: black;
    color: white;
    border: 1px solid yellow;
    font-size: 20px;
  } */
  