.login-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: black;
}
  
.login-form {
    background-color: #333;
    color: #fff;
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 5px;
}
  
input[type="email"],
input[type="password"] {
    width: 100%;
    padding: 10px;
    margin-bottom: 10px;
}
  
button {
    padding: 10px 20px;
    background-color: #07FBCC;
    color: #333;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}
  
button:hover {
    background-color: #05d9a7;
}
  