.scrollableDiv {
    /* margin-top: 5px; */
    overflow: auto;
    max-height: 80vh;
    scrollbar-width: none; /* For Firefox */
    -ms-overflow-style: none; /* For Internet Explorer and Edge */
}

/* Hide scrollbar for WebKit-based browsers (Chrome, Safari) */
.scrollableDiv::-webkit-scrollbar {
    display: none;
}

.no-bottom-border {
    border-bottom: 0 !important;
}