.full-height-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 90vh;
}

/* Ensure the parent container uses flexbox and occupies the full height of the viewport */
.parent-container {
    display: flex;
    flex-direction: column;
    height: 70vh;
    width: 100%;
  }
  
  /* Ensure the chat container is aligned to the bottom and is scrollable */
  .chat-container {
    display: flex;
    flex-direction: column-reverse;
    flex-grow: 1;
    justify-content: flex-end;
    padding: 20px;
    overflow-y: auto;
  }
  
  /* Messages container should fill available space and be scrollable */
  .messages {
    flex-grow: 1;
    display: flex;
    flex-direction: column-reverse;
    overflow-y: auto;
    padding: 10px;
    scrollbar-width: none;
    -ms-overflow-style: none;
  }