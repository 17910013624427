.video-chat-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    background-color: #f0f0f0;
  }
  
  .video-container {
    display: flex;
    justify-content: space-around;
    width: 100%;
    max-width: 800px;
    margin-bottom: 20px;
  }
  
  .video-element {
    width: 45%;
    border: 2px solid #ccc;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  }
  
  .end-call-button {
    padding: 10px 20px;
    font-size: 16px;
    color: white;
    background-color: red;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .end-call-button:hover {
    background-color: darkred;
  }
  